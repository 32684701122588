<template>
  <v-container v-if="paramSelected">
    <v-row>
      <v-col style="font-size: 20px; text-align: center" id="ValuesParamsAxisGeometryGradeTitle">
        <strong> {{ selectedLanguage['curves_component_text1'] }} </strong>
      </v-col>
    </v-row>
    <v-row style="margin: 12px;">
      <div id="ValuesParamsAxisGeometryMaxGrade" class="adjustGroup">
        <v-text-field
          dense
          outlined
          hide-details="auto"
          :label="selectedLanguage['curves_component_text2']"
          v-model.number="paramSelected.values_params.axis_geometry.max_grade"
        />
        <v-tooltip max-width="600" right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="elevation-0 no-background-hover" :ripple="false" icon v-bind="attrs" v-on="on" >
            <v-icon style="margin-left: 10px;">
              mdi-information-outline
            </v-icon>
            </v-btn>
          </template>
          <span>{{ selectedLanguage['help_text_1_tooltip'] }}</span>
        </v-tooltip>
      </div>
      <v-spacer />
      <div id="ValuesParamsAxisGeometryMinGrade" class="adjustGroup">
        <v-text-field
          dense
          outlined
          hide-details="auto"
          :label="selectedLanguage['curves_component_text3']"
          v-model.number="paramSelected.values_params.axis_geometry.min_grade"
        />
      </div>
      <v-tooltip max-width="600" right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="elevation-0 no-background-hover" :ripple="false" icon v-bind="attrs" v-on="on" >
            <v-icon style="margin-left: 10px;">
              mdi-information-outline
            </v-icon>
            </v-btn>
          </template>
          <span>{{ selectedLanguage['help_text_2_tooltip'] }}</span>
        </v-tooltip>
      <v-spacer />
      <div id="ValuesParamsAxisGeometryVerticalCurveLength" class="adjustGroup">
        <v-text-field
          dense
          outlined
          hide-details="auto"
          :label="selectedLanguage['curves_component_text4']"
          v-model.number="
            paramSelected.values_params.axis_geometry.vertical_curve_length
          "
        />
        <v-tooltip max-width="600" right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="elevation-0 no-background-hover" :ripple="false" icon v-bind="attrs" v-on="on" >
            <v-icon style="margin-left: 10px;">
              mdi-information-outline
            </v-icon>
            </v-btn>
          </template>
          <span>{{ selectedLanguage['help_text_3_tooltip'] }}</span>
        </v-tooltip>
      </div>
    </v-row>
    <br /><v-divider id="ValuesParamsAxisGeometryDivider" /><br />
    <v-row style="display: flex; flex-direction: row; justify-content: center; margin-top: 20px; margin-bottom: 50px; font-size: 24px;">
      <h3 id="ValuesParamsAxisGeometryTitle">
        {{ selectedLanguage['curves_component_text5'] }}
      </h3>
    </v-row>
    <SvgCalcSuperElevationComponent :paramSelected="paramSelected" id="ValuesParamsAxisGeometry"/>
    <v-col class="formCardStyle">
      <v-row id="ValuesParamsSuperelevationOperationalSpeed">
        <v-col style="align-self: center"> {{ selectedLanguage['curves_component_text6'] }} </v-col>
        <v-col style="align-self: center">
          <v-select
          id="speeds"
            v-model="
              selectedItem
            "
            :items="speedListItems"
            :label="selectedLanguage['curves_component_text7']"
            item-text="speed"
            item-value="coef"
            return-object
            single-line
            @change="newCurvesCalc"
          ></v-select>
        </v-col>
        <v-row style="align-self: center">
          <div>
            km/h
          </div>
          <v-tooltip max-width="600" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="elevation-0 no-background-hover" :ripple="false" icon v-bind="attrs" v-on="on" >
              <v-icon style="margin-left: 10px;">
                mdi-information-outline
              </v-icon>
              </v-btn>
            </template>
            <span>{{ selectedLanguage['help_text_4_tooltip'] }}</span>
          </v-tooltip>
        </v-row>
      </v-row>
    </v-col>
    <v-col style="padding-top: 30px; padding-bottom: 50px;" id="ValuesParamsSuperelevationSpeedCoeficientTitle">
      <v-row style="display: flex; flex-direction: row; justify-content: center; align-items: center; margin-top: 20px; margin-bottom: 50px; font-size: 24px;">
        <h3 style="line-height: normal;">
          {{ selectedLanguage['curves_component_text8'] }}
        </h3>
      </v-row>
      <v-row style="overflow-x: auto;" id="ValuesParamsSuperelevationSpeedCoeficient">
        <table style="width: 100%;" id="roadwayTable">
          <thead>
            <tr>
              <th style="text-align: center" rowspan="3" width="64"><strong>{{ selectedLanguage['curves_component_text9'] }}</strong></th>
              <th style="text-align: center" colspan="6" width="384"><strong>{{ selectedLanguage['curves_component_text10'] }}&nbsp;</strong>
                <v-tooltip max-width="600" right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="elevation-0 no-background-hover" :ripple="false" icon v-bind="attrs" v-on="on" >
                    <v-icon style="margin-left: 10px;">
                      mdi-information-outline
                    </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ selectedLanguage['help_text_5_tooltip'] }}</span>
                </v-tooltip>
              </th>
            </tr>
            <tr>
              <th style="text-align: center" rowspan="2">O</th>
              <th style="text-align: center" rowspan="2">I</th>
              <th style="text-align: center" rowspan="2">II</th>
              <th style="text-align: center" rowspan="2">III</th>
              <th style="text-align: center" colspan="2">IV</th>
            </tr>
            <tr>
              <th style="text-align: center">A</th>
              <th style="text-align: center">B</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in roadClassesList" :key="item.name">
              <td style="text-align: center">{{ item.name }}</td>
              <td style="text-align: center; cursor: pointer;" class="unselect" onMouseOver="this.style.background='#bbb'" onMouseOut="this.style.background='#FFF'" :id="'zero'+item.zero" @click="selectMinRadius(item.zero,10,'zero'+item.zero, item.name ,2 )">{{ item.zero }}</td>
              <td style="text-align: center; cursor: pointer;" class="unselect" onMouseOver="this.style.background='#bbb'" onMouseOut="this.style.background='#FFF'" :id="'I'+item.I" @click="selectMinRadius(item.I,10,'I'+item.I, item.name ,3)">{{ item.I }}</td>
              <td style="text-align: center; cursor: pointer;" class="unselect" onMouseOver="this.style.background='#bbb'" onMouseOut="this.style.background='#FFF'" :id="'II'+item.II" @click="selectMinRadius(item.II,8,'II'+item.II, item.name ,4)">{{ item.II }}</td>
              <td style="text-align: center; cursor: pointer;" class="unselect" onMouseOver="this.style.background='#bbb'" onMouseOut="this.style.background='#FFF'" :id="'III'+item.III" @click="selectMinRadius(item.III,8,'III'+item.III, item.name ,5)">{{ item.III }}</td>
              <td style="text-align: center; cursor: pointer;" class="unselect" onMouseOver="this.style.background='#bbb'" onMouseOut="this.style.background='#FFF'" :id="'A'+item.A" @click="selectMinRadius(item.A,8,'A'+item.A, item.name ,6)">{{ item.A }}</td>
              <td style="text-align: center; cursor: pointer;" class="unselect" onMouseOver="this.style.background='#bbb'" onMouseOut="this.style.background='#FFF'" :id="'B'+item.B" @click="selectMinRadius(item.B,8,'B'+item.B, item.name ,7)">{{ item.B }}</td>
            </tr>
          </tbody>
        </table>
      </v-row>
    </v-col>
    <v-col>
      <v-row style="justify-content: center" id="ValuesParamsCurveChart">
        <v-data-table
          :headers="curvesHeaders"
          :hide-default-footer="true"
          :items="selectedCurves"
          class="elevation-0"
          disable-pagination
        >
          <template v-slot:top>
            <v-row class="mt-4" style="margin: 12px;">
              <v-toolbar flat>
                <v-toolbar-title>{{ selectedLanguage['curves_component_text11'] }}</v-toolbar-title>
                <v-tooltip max-width="600" right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="elevation-0 no-background-hover" :ripple="false" icon v-bind="attrs" v-on="on" >
                    <v-icon style="margin-left: 10px;">
                      mdi-information-outline
                    </v-icon>
                    </v-btn>
                  </template>
                  <span style="max-width: 50vw;">Coloque comprimentos de espiral ou zero, caso seja uma curva circular sem transição. Coloque penalidade em curvas de raios menores, para que essas sejam usadas apenas em caso de necessidade. A superelevação de cada curva é calculada automaticamente. Inclua, exclua e edite linhas da tabela de curvas, conforme necessidade.</span>
                </v-tooltip>
              </v-toolbar>
              <DialogComponent
              :buttonText="selectedLanguage['curves_component_text12']"
                :titleDialog="selectedLanguage['curves_component_text12']"
                @save-item-dialog="handleNewItemCurve"
              >
                <v-row>
                  <v-col cols="4" sm="6" md="4">
                    <v-text-field
                      v-model="newItem.Radius"
                      :label="selectedLanguage['curves_component_text13']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="6" md="4">
                    <v-text-field
                      v-model="newItem.Ls"
                      :label="selectedLanguage['curves_component_text14']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="6" md="4">
                    <v-text-field
                      v-model="newItem.Penalty"
                      :label="`${selectedLanguage['curves_component_text15']} (${paramSelected.currency}/m)`"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </DialogComponent>
            </v-row>
          </template>
          <template v-slot:item.Radius="props">
            <v-edit-dialog
              color="primary"
              :return-value.sync="props.item.Radius"
              large
              @save="editTable(props.item)"
            >
              <div>{{ props.item.Radius }}</div>
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Radius"
                  :label="selectedLanguage['curves_component_text16']"
                  single-line
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.Ls="props">
            <v-edit-dialog
              color="primary"
              :return-value.sync="props.item.Ls"
              large
              @save="editTable(props.item)"
            >
              <div>{{ props.item.Ls }}</div>
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Ls"
                  :label="selectedLanguage['curves_component_text16']"
                  single-line
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.Penalty="props">
            <v-edit-dialog
              color="primary"
              :return-value.sync="props.item.Penalty"
              large
              @save="editTable(props.item)"
            >
              <div>{{ props.item.Penalty }}</div>
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.Penalty"
                  :label="selectedLanguage['curves_component_text16']"
                  single-line
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import SvgCalcSuperElevationComponent from "@/components/SvgComponents/roadwayDefaults/superElevation/SvgCalcSuperElevationComponent.vue";
import DialogComponent from "@/components/DialogComponent.vue";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  components: {
    SvgCalcSuperElevationComponent,
    DialogComponent,
  },
  mounted: async function () {
    if(this.paramSelected.type_name === 'railway'){
      this.selectedRailTypeModel =
      this.selectedRailTypes[
        this.paramSelected.values_params.superelevation.boleto_index
      ];
    }
    this.delay(1000).then(() => this.newCurvesCalc());

    await this.initCurvesTableTranslation()

    setTimeout(() => {
      if(this.paramSelected.values_params.axis_geometry.min_circular_curve_radius == 540){
        document.getElementById('zero540').style.color = '#0F0';
      } else if (this.paramSelected.values_params.axis_geometry.min_circular_curve_radius == 375){
        document.getElementById('I375').style.color = '#0F0';
      } else if (this.paramSelected.values_params.axis_geometry.min_circular_curve_radius == 345){
        document.getElementById('zero345').style.color = '#0F0';
      }  else if (this.paramSelected.values_params.axis_geometry.min_circular_curve_radius == 230){
        document.getElementById('III230').style.color = '#0F0';
      } else if (this.paramSelected.values_params.axis_geometry.min_circular_curve_radius == 210){
        document.getElementById('I210').style.color = '#0F0';
      }  else if (this.paramSelected.values_params.axis_geometry.min_circular_curve_radius == 170){
        document.getElementById('II170').style.color = '#0F0';
      } else if (this.paramSelected.values_params.axis_geometry.min_circular_curve_radius == 125){
        document.getElementById('B125').style.color = '#0F0';
      } else if (this.paramSelected.values_params.axis_geometry.min_circular_curve_radius == 115){
        document.getElementById('I115').style.color = '#0F0';
      } else if (this.paramSelected.values_params.axis_geometry.min_circular_curve_radius == 80){
        document.getElementById('II80').style.color = '#0F0';
      } else if (this.paramSelected.values_params.axis_geometry.min_circular_curve_radius == 50){
        document.getElementById('III50').style.color = '#0F0';
      } else if (this.paramSelected.values_params.axis_geometry.min_circular_curve_radius == 25){
        document.getElementById('B25').style.color = '#0F0';
      }
    }, 1000);

    setTimeout(()=>{
      if(this.paramSelected.values_params.imgs.road_table_selected.name != null){
        document.getElementById(this.paramSelected.values_params.imgs.road_table_selected.name).style.color = '#0F0';
      }
    }, 1000)
  },
  computed: {
    ...mapGetters(["paramSelected", "selectedCurves", "selectedRailTypes","profile"]),
    safety() {
      return (
        this.paramSelected.values_params.superelevation.security_coeficient * 1
      );
    },
    helper(){
        return this.$store.getters.helper
      },
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
    curvesHeaders() {
      return this.listArrOfObject
    },
  },
  data() {
    return {
      color: "#0A7373",
      gaugeList: [1.0, 1.435, 1.6],
      roadClassesList: [
        {name: 'Plain field', zero: 540, I: 375, II: 375, III: 230, A: 230, B: 125},
        {name: 'Irregular field', zero: 345, I: 210, II: 170, III: 125, A: 125, B: 50},
        {name: 'Mountainous region', zero: 210, I: 115, II: 80, III: 50, A: 50, B: 25}
      ],
      roadwayTable: [
        ['Region',	'O',	'I',	'II',	'III', 'IV', ''],
        ['',	'',	'',	'',	'',	'A', 'B'],
        ['Plain field',	'540',	'375',	'375',	'230',	'230',	'125'],
        ['Irregular field',	'345',	'210',	'170',	'125',	'125',	'50'],
        ['Mountainous region',	'210',	'115',	'80',	'50',	'50',	'25']
      ],
      speedListItems: [
        {speed: 30, coef: 0.2, maxCurve: 450},
        {speed: 40, coef: 0.18, maxCurve: 800},
        {speed: 50, coef: 0.16, maxCurve: 1250},
        {speed: 60, coef: 0.151, maxCurve: 1800},
        {speed: 70, coef: 0.15, maxCurve: 2450},
        {speed: 80, coef: 0.141, maxCurve: 3200},
        {speed: 90, coef: 0.14, maxCurve: 4050},
        {speed: 100, coef: 0.13, maxCurve: 5000},
        {speed: 110, coef: 0.12, maxCurve: 5000},
        {speed: 120, coef: 0.11, maxCurve: 5000},
      ],
      selectedItem: {speed: 80, coef: 0.14, maxCurve: 3200},
      selectedRailTypeModel: { Boleto: 0 },
      newItem: { Radius: "", Ls: null, Penalty: null },
      maxE: 10,
      listArrOfObject: [
        { text: "Radius (m)", value: "Radius" },
        { text: "Spiral (m)", value: "Ls" },
        { text: "Penalty (/m)", value: "Penalty", },
        { text: "Superelevation (%)", value: "Superelevation" },
        { text: "Actions", value: "actions", sortable: false },
        ],
    };
  },
  methods: {

    initCurvesTableTranslation(){
      // console.log('speed ', this.paramSelected.values_params.superelevation.operational_speed)
      if(this.paramSelected.values_params.superelevation.operational_speed == 30){ this.selectedItem = {speed: 30, coef: 0.2, maxCurve: 450} }
      else if(this.paramSelected.values_params.superelevation.operational_speed == 40){ this.selectedItem = {speed: 40, coef: 0.18, maxCurve: 800} }
      else if(this.paramSelected.values_params.superelevation.operational_speed == 50){ this.selectedItem = {speed: 50, coef: 0.16, maxCurve: 1250} }
      else if(this.paramSelected.values_params.superelevation.operational_speed == 60){ this.selectedItem = {speed: 60, coef: 0.151, maxCurve: 1800} }
      else if(this.paramSelected.values_params.superelevation.operational_speed == 70){ this.selectedItem = {speed: 70, coef: 0.15, maxCurve: 2450} }
      else if(this.paramSelected.values_params.superelevation.operational_speed == 80){ this.selectedItem = {speed: 80, coef: 0.141, maxCurve: 3200} }
      else if(this.paramSelected.values_params.superelevation.operational_speed == 90){ this.selectedItem = {speed: 90, coef: 0.14, maxCurve: 4050} }
      else if(this.paramSelected.values_params.superelevation.operational_speed == 100){ this.selectedItem = {speed: 100, coef: 0.13, maxCurve: 5000} }
      else if(this.paramSelected.values_params.superelevation.operational_speed == 110){ this.selectedItem = {speed: 110, coef: 0.12, maxCurve: 5000} }
      else if(this.paramSelected.values_params.superelevation.operational_speed == 120){ this.selectedItem = {speed: 120, coef: 0.11, maxCurve: 5000} }
      this.roadClassesList = this.selectedLanguage['curvesTable_roadway_text2']
      this.roadwayTable = this.selectedLanguage['curvesTable_roadway_text1']
      this.listArrOfObject = this.selectedLanguage['curves_comp_list_arr_of_obj_1']
    },

    async selectMinRadius(selected, maxE, idCell, line, col){

      const cells = document.querySelectorAll('.unselect');

      await cells.forEach(cell => {
        cell.style.color = '#000000';
      });

      document.getElementById(idCell).style.color = '#0F0';

      this.paramSelected.values_params.axis_geometry.min_circular_curve_radius = selected
      this.maxE = maxE
      if(line == 'Plain field'){
        line = 2
      } else if(line == 'Irregular field'){
        line = 3
      } else if(line == 'Mountainous region'){
        line = 4
      } else if(line == 'Terreno plano'){
        line = 2
      } else if(line == 'Terreno irregular'){
        line = 3
      } else if(line == 'Região montanhosa'){
        line = 4
      }
      this.paramSelected.values_params.imgs.road_table_selected = {line: line, col: col, name: idCell}
      this.paramSelected.values_params.imgs.roadway_table = this.roadwayTable
      this.$emit('callWatcher')
      this.newCurvesCalc()
    },
    delay(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    newCurvesCalc() {
      const Cb = 4 * this.currentCalc.tax // colocar cotação do dolar aqui e substituir o antigo por esse novo em todos os lugares
      let Rref, m, k

      this.currentCurves.forEach((item) => {
        
        var scoef;
        if (this.currentCalc.values_params.superelevation.speed_coeficient === "3/4 (v)") {
          scoef = 0.75;
        } else if (this.currentCalc.values_params.superelevation.speed_coeficient === "2/3 (v)") {
          scoef = (2 / 3).toFixed(2);
        } else if (this.currentCalc.values_params.superelevation.speed_coeficient === "1/√2 (v)") {
          scoef = (1 / Math.pow(2, 0.5)).toFixed(2);
        } else {
          scoef = 1;
        }

        
        item.Superelevation = (
          ((((this.currentCalc.values_params.superelevation.gauge * 1000 + this.selectedRailTypeModel.Boleto) *
            Math.pow(this.currentCalc.values_params.superelevation.operational_speed * scoef, 2)) /
            (127 * item.Radius)) /
          1000
        ) * 100).toFixed(2)

        
        if (this.currentCalc.values_params.superelevation.speed_coeficient === "2/3 (St)") {
          item.Superelevation = ((item.Superelevation * 2) / 3).toFixed(2)
        }

        
        if (item.Superelevation > 8.0) {
          item.Superelevation = 8.0.toFixed(2)
        }

        // console.log('calc is ', this.currentCalc)
        if (this.currentCalc.type.toUpperCase() === 'RAILWAY') {
          if (this.currentCalc.sub_type.toUpperCase().includes('CARGO')) {
            Rref = 2500;
          } else if (this.currentCalc.sub_type.toUpperCase().includes('HIGH SPEED')) {
            Rref = 12000;
          } else {
            Rref = 1500;
          }
          m = 2;
          k = 15;
        } else {
          if (this.currentCalc.sub_type.toUpperCase() === 'RURAL') {
            Rref = 120;
          } else if (this.currentCalc.sub_type.toUpperCase() === 'VICINAL') {
            Rref = 240;
          } else {
            Rref = 800;
          }
          m = 5;
          k = 1;
        }

        // Aplicação da penalidade
        if (item.Radius > Rref) {
          item.Penalty = 0;
        } else {
          const penalty = parseFloat((Cb * Math.pow((this.currentCalc.values_params.superelevation.operational_speed * k / item.Radius), m)).toFixed(2));
          item.Penalty = penalty;
        }

        // Atualiza parâmetros de superelevação
        this.currentCalc.values_params.superelevation.boleto_width = this.selectedRailTypeModel.Boleto;
        this.currentCalc.values_params.superelevation.security_coeficient = scoef;
      });
      // console.log('list ', this.currentCalc.values_params)
    },
    // recalculateSuperelevation() {

    //   this.paramSelected.values_params.superelevation.operational_speed = this.selectedItem.speed;

    //   var scoef = 0.15;

    //   this.selectedCurves.forEach((item) => {
    //     if (this.selectedItem.maxCurve <= item.Radius) {
    //       item.Superelevation = 0;
    //     } else {
    //       let speed = this.paramSelected.values_params.superelevation.operational_speed;
    //       let radius = item.Radius;

    //       if (typeof speed === 'number' && !isNaN(speed) && !isNaN(radius) && radius > 0) {
    //         let speedMS = speed * 1000 / 3600;
            
    //         let superElevation = (Math.pow(speedMS, 2) / (9.81 * radius)) - scoef;

    //         superElevation = Math.max(superElevation, 0);

    //         superElevation = Math.min(superElevation, 0.10);

    //         item.Superelevation = (superElevation * 100).toFixed(2);
    //       } else {
    //         item.Superelevation = 0;
    //       }
    //     }
    //   });
    // },
    onChangeSelectedRailType() {
      this.paramSelected.values_params.superelevation.boleto_index =
        this.selectedRailTypeModel.Id;
      this.newCurvesCalc();
    },
    async editTable() {
      this.newCurvesCalc();
    },
    deleteItem(itemToDelete) {
      const indexToDelete = this.selectedCurves.findIndex(
        (item) => item === itemToDelete
      );

      if (indexToDelete !== -1) {
        this.selectedCurves.splice(indexToDelete, 1);
        this.paramSelected.values_params.axis_geometry.list_curves.splice(indexToDelete, 1);
      }
    },
    handleNewItemCurve() {
      this.selectedCurves.push(this.newItem);
      this.newCurvesCalc();
      this.resetNewItem();
    },
    resetNewItem() {
      const defaultValues = {
        Radius: "",
        Ls: null,
        Penalty: null,
      };

      this.newItem = defaultValues;
    },
  },
};
</script>

<style scoped>
.adjustGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
th{
  padding: 10px;
  font-size: 22px;
}
td {
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  padding: 10px;
}
.unselect{
  color: #000000;
}
.st0 {
  fill: none;
  stroke: #000000;
  stroke-miterlimit: 10;
}
.st1 {
  fill: none;
  stroke: #3e5aa9;
  stroke-miterlimit: 10;
}
.st2 {
  fill: #3e5aa9;
}
.st3 {
  stroke: #000000;
  stroke-miterlimit: 10;
}
.st4 {
  fill: none;
  stroke: #0c7f40;
  stroke-miterlimit: 10;
}
.st5 {
  fill: none;
  stroke: #0c7f40;
  stroke-miterlimit: 10;
  stroke-dasharray: 10;
}
.st6 {
  fill: none;
  stroke: #000000;
  stroke-miterlimit: 10;
  stroke-dasharray: 10;
}
.st7 {
  fill: none;
  stroke: #3e5aa9;
  stroke-miterlimit: 10;
  stroke-dasharray: 10;
}
.st8 {
  fill: none;
  stroke: #3e5aa9;
  stroke-miterlimit: 10;
  stroke-dasharray: 9.881, 9.881;
}
.st9 {
  font-family: "MyriadPro-Regular";
}
.st10 {
  font-size: 15.7386px;
}
.st11 {
  fill: #ff7f00;
}
.st12 {
  font-size: 24.018px;
}
.st13 {
  fill: #5b9f71;
}
.st14 {
  font-size: 18.5px;
}
.st15 {
  font-size: 18.4984px;
}
.st16 {
  fill: none;
  stroke: #f04838;
  stroke-miterlimit: 10;
  stroke-dasharray: 10;
}
.st17 {
  fill: none;
  stroke: #5b9f71;
  stroke-miterlimit: 10;
}
.st18 {
  fill: none;
  stroke: #ff0659;
  stroke-miterlimit: 10;
}
.st19 {
  font-size: 15.6606px;
}
.st20 {
  fill: none;
  stroke: #ff7f00;
  stroke-miterlimit: 10;
}
.st21 {
  font-size: 16.7774px;
}
.st22 {
  fill: #ff0659;
}
.curve_st0 {
  clip-path: url(#SVGID_2_);
  fill: #00ccc0;
}
.curve_st1 {
  clip-path: url(#SVGID_2_);
  fill: #72f2eb;
}
.curve_st2 {
  clip-path: url(#SVGID_2_);
  fill: none;
  stroke: #0a4741;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.curve_st3 {
  clip-path: url(#SVGID_2_);
  fill: #b1fcf6;
}
.curve_st4 {
  clip-path: url(#SVGID_2_);
  fill: none;
  stroke: #1b7f79;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.curve_st5 {
  clip-path: url(#SVGID_2_);
}
.curve_st6 {
  fill: #00ccc0;
}
.curve_st7 {
  fill: #72f2eb;
}
.curve_st8 {
  clip-path: url(#SVGID_2_);
  fill: #747f7f;
}
.curve_st9 {
  fill: none;
  stroke: #00ccc0;
  stroke-width: 5;
  stroke-miterlimit: 10;
}
.curve_st10 {
  fill: #ffffff;
}
.curveImage {
  padding: 10px;
  max-height: 472px;
}
@media only screen and (max-width: 1280px) {
  .curveImage {
    padding: 10px;
    max-height: auto;
  }
}
.nextstep2 {
  padding: 5px;
  background-color: #ff8409;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  min-width: 290px;
  height: 40px;
}
</style>
