<template>
    <div>
    
        <v-app-bar app clipped-left :color="color" dense dark style="overflow-x: auto;">
            <v-app-bar-nav-icon to="/"><v-img :src="logoIconUrl" width="10px" alt="wwcalc"/></v-app-bar-nav-icon>
            <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab key="capex" @click="setChangedFields(numerosUnicosOrdenados)" v-if="capexChanged === true">Capex</v-tab>
            <v-tab key="expropriation" @click="getRasterBudgetChangesList()" v-if="notInUse === true">{{ selectedLanguage['updateDefaultParams_component_text1'] }}</v-tab>
            <v-tab key="curves" @click="setCurvesTab(listValuesCurve, valuesParamsKeys)" v-if="curvesChanged === true">{{ selectedLanguage['updateDefaultParams_component_text2'] }}</v-tab>
            <v-tab key="platform" @click="setPlatformTab(listValuesPlatform, valuesParamsKeys)" v-if="platformChanged === true">{{ selectedLanguage['updateDefaultParams_component_text3'] }}</v-tab>
            <v-tab key="earthwork" @click="setEarthWTab(listValuesEarthW, valuesParamsKeys)" v-if="earthWorkChanged === true">{{ selectedLanguage['updateDefaultParams_component_text4'] }}</v-tab>
            </v-tabs>
            <v-btn :loading="loading1" text @click="clickSaveExit()">
            {{ selectedLanguage['updateDefaultParams_component_text5'] }}
            </v-btn>
        </v-app-bar>

        <v-alert v-if="loadVersionData == true" border="top" dense color="info" dark>
            <v-progress-circular indeterminate :color="color"></v-progress-circular>
            {{ selectedLanguage['updateDefaultParams_component_text6'] }}
        </v-alert>

        <div v-for="(item, idx) in initialList" :key="idx">
        <div :class="idx+' selecao'">
            <v-select label="Select" :items="item" v-model="itemOnList"></v-select>
            <v-btn @click="exitSelecao()">Cancel</v-btn>
            <v-btn @click="saveSelecao(itemOnList)">Save</v-btn>
        </div>
        </div>
    
        <v-card v-if="paramSelected" class="elevation-0">
        <v-tabs-items v-model="tab">
            <v-tab-item key="capex" v-if="capexChanged === true" style="padding: 30px;">
                <v-card-text>
                <v-data-table :headers="capexHeaders" :items="selectedCapex" hide-default-footer disable-pagination item-key="name" dense class="elevation-0">
                    <template v-slot:item="{item, index}">
                        <tr v-if="item.enable" :class="capexItemStyle(item)+' trBody'" style="opacity: 0.2; display: none;" :id="index">
                        <td class="codeS">
                        {{item['Code']}}
                        </td>
                        <td style="height: auto;" :id="item['Code']" :class="item['Code']+'value'">
                        {{item['Description']}}
                        </td>
                        <td v-if="item.Unit!=0" :class="item['Code']+'value'">
                        {{item['Unit']}}
                        </td>
                        <td v-else>
                        </td>
                        <td v-if="item.Unit!=0">
                        <v-edit-dialog :class="item['Code']+'value'" :return-value.sync="item['Unit Cost']" @save="save" :save-text="selectedLanguage['capex_dialog_text_btn_1']" :cancel-text="selectedLanguage['capex_dialog_text_btn_2']" @cancel="cancel" @open="open" @close="close" large> {{item['Unit Cost']}}
                            <template v-slot:input>
                            <v-text-field :class="item['Code']+'value'" v-model.number="item['Unit Cost']" label="Edit" single-line></v-text-field>
                            </template>
                        </v-edit-dialog>
                        </td>
                        <td v-else>
                        </td>
                        <td v-if="item.Unit!=0">
                        <v-edit-dialog :return-value.sync="item['Risk']" @save="save" :save-text="selectedLanguage['capex_dialog_text_btn_1']" :cancel-text="selectedLanguage['capex_dialog_text_btn_2']" @cancel="cancel" @open="open" @close="close" large> {{item['Risk']}}
                            <template v-slot:input>
                            <v-text-field v-model.number="item['Risk']" label="Edit" single-line></v-text-field>
                            </template>
                        </v-edit-dialog>
                        </td>
                        <td v-else>
                        </td>
                    </tr>
                    </template>
                </v-data-table>
                </v-card-text>
            </v-tab-item>
    
            <v-tab-item key="expropriation" @click="getRasterBudgetChangesList()" v-if="notInUse === true" style="padding: 30px;">
                <v-card-text>
                <v-data-table :headers="expropriationHeaders" :items="selectedExpropriation" hide-default-footer disable-pagination item-key="Code" dense class="elevation-0">
                    <template v-slot:item="{item, index}">
                        <tr :class="expropriationItemStyle(item)+' trBody'" style="opacity: 0.2; display: none;" :id="'protectClass'+(index-1)">
                        <td class="codeS">
                        {{item['Code']}}
                        </td>
                        <td style="height: auto;">
                        {{item['Description']}}
                        </td>
                        <td>
                        <v-edit-dialog :return-value.sync="item['Cost']" @save="save" :save-text="selectedLanguage['capex_dialog_text_btn_1']" :cancel-text="selectedLanguage['capex_dialog_text_btn_2']" @cancel="cancel" @open="open" @close="close" large> {{item['Cost']}}
                            <template v-slot:input>
                            <v-text-field v-model.number="item['Cost']" label="Edit" single-line></v-text-field>
                            </template>
                        </v-edit-dialog>
                        </td>
                    </tr>
                    </template>
                </v-data-table>
                </v-card-text>
            </v-tab-item>
            <v-tab-item key="curves" v-if="curvesChanged === true" style="padding: 30px;">
            <v-card-text>
                <Curves :callWatcher="callWatcher(this.paramSelected.values_params.imgs.curves_table_selected)"/>
            </v-card-text>
            </v-tab-item>
            <v-tab-item key="platform" v-if="platformChanged === true" style="padding: 30px;">
            <v-card-text>
                <v-row>
                    <v-col style="align-self: center;" id="ValuesParamsPlatformLanes">
                    <v-select
                    outlined
                    v-model="centralBarrierSwitch"
                    :items="[this.selectedLanguage['adjustParameters_component_text15'], this.selectedLanguage['adjustParameters_component_text14']]"
                    item-value="id"
                    label="Has Central barrier?"
                    @input="onCentralBSelected"
                    />
                    <v-text-field
                    outlined
                    v-model="paramSelected.values_params.platform.lanes"
                    id="Lanes"
                    :label="selectedLanguage['railway_params_lable_1']"
                    @input="onLanesSet"
                    />
                    <v-select disabled
                    outlined
                    v-model="
                        paramSelected.values_params.superstructure.central_barrier
                    "
                    :items="['None']"
                    item-text="name"
                    item-value="id"
                    label="Central barrier"
                    @input="onCentralBarrierSelected"
                    v-if="centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14']"
                    />
                    <v-select
                    outlined
                    v-model="
                        paramSelected.values_params.superstructure.central_barrier
                    "
                    :items="['None', 'New Jersey', 'Metal Defense']"
                    item-text="name"
                    item-value="id"
                    label="Central barrier"
                    @input="onCentralBarrierSelected"
                    v-if="centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15']"
                    />
                    <v-select
                    outlined
                    v-model="
                        paramSelected.values_params.superstructure.side_barrier
                    "
                    :items="['None', 'New Jersey', 'Metal Defense']"
                    item-text="name"
                    item-value="id"
                    label="Side barrier"
                    @input="onSideBarrierSelected"
                    />
                </v-col>
                </v-row>

                <PlatformAquisition1Lanes  v-if="centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14']" :params="selectedOptions"  />
                <PlatformAquisition2Lanes  v-else :params="selectedOptions"  />

                <br /><v-divider id="divider1"/><br />
                <v-row v-if="centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14']" >
                <v-col></v-col>
                <v-col cols="12">
                    <Platform1Lane  :params="selectedOptions" />
                </v-col>
                <v-col></v-col>
                </v-row>
                <v-row v-else>
                <v-col></v-col>
                <v-col cols="12">
                    <Platform2Lanes  :params="selectedOptions" :callWatcher="callWatcher(this.paramSelected.values_params.platform.median_width)" />
                </v-col>
                <v-col></v-col>
                </v-row>
                <br /><v-divider id="divider2"/><br />
                <PlatformBridge />
                <br />
                <v-col cols="12">
                <v-spacer />
                <PlatformBridge1Lane 
                    v-if="centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14']"
                    :params="selectedOptions"
                />
                <PlatformBridge2Lanes  v-else :params="selectedOptions" />
                <v-spacer />
                </v-col>
                <br /><v-divider id="divider3"/><br />
                <PlatformTunnel  />
                <br /><br />
                <v-col cols="12">
                <v-spacer />
                <PlatformTunnel1Lane 
                    v-if="centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14']"
                    :params="selectedOptions"
                />
                <PlatformTunnel2Lanes  v-else :params="selectedOptions" />
                <v-spacer />
                </v-col>
            </v-card-text>
            </v-tab-item>
            <v-tab-item key="earthwork">
            <v-card-text>
                <br />
                <br />
                <EarthworkTransport />
                <br /><v-divider id="divider4"/><br />
                <EarthworkCut1Lane
                v-if="centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14']"
                :params="selectedOptions"
                />
                <EarthworkCut2Lanes v-else :params="selectedOptions" />
                <br /><v-divider id="divider5"/><br />
                <EarthworkFill1Lane
                v-if="centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14']"
                :params="selectedOptions"
                />
                <EarthworkFill2Lanes v-else :params="selectedOptions" />
            </v-card-text>
            </v-tab-item>
            </v-tabs-items>
        </v-card>
        </div>
    </template>
    
    <script>
        import { mapActions, mapGetters } from 'vuex'
        import Curves from "./paramsDefaults/Curves.vue";
        import PlatformAquisition1Lanes from "./paramsDefaults/PlatformAquisition1Lanes.vue";
        import PlatformAquisition2Lanes from "./paramsDefaults/PlatformAquisition2Lanes.vue";
        import Platform1Lane from "./paramsDefaults/Platform1Lane.vue";
        import Platform2Lanes from "./paramsDefaults/Platform2Lanes.vue";
        import PlatformBridge from "./paramsDefaults/PlatformBridge.vue";
        import PlatformBridge1Lane from "./paramsDefaults/PlatformBridge1Lane.vue";
        import PlatformBridge2Lanes from "./paramsDefaults/PlatformBridge2Lanes.vue";
        import PlatformTunnel from "./paramsDefaults/PlatformTunnel.vue";
        import PlatformTunnel1Lane from "./paramsDefaults/PlatformTunnel1Lane.vue";
        import PlatformTunnel2Lanes from "./paramsDefaults/PlatformTunnel2Lanes.vue";

        import EarthworkTransport from "./paramsDefaults/EarthworkTransport.vue";
        import EarthworkCut1Lane from "./paramsDefaults/EarthworkCut1Lane.vue";
        import EarthworkCut2Lanes from "./paramsDefaults/EarthworkCut2Lanes.vue";
        import EarthworkFill1Lane from "./paramsDefaults/EarthworkFill1Lane.vue";
        import EarthworkFill2Lanes from "./paramsDefaults/EarthworkFill2Lanes.vue";
        import { listValuesParams } from '../../../../listValuesParams.js'
        import { listValuesParamsPlatform } from '../../../../listValuesParamsPlatform.js'
        import { listValuesParamsEarthW } from '../../../../listValuesParamsEarthW.js'
        import { portuguese } from '../../../languages/pt-br'
        import { english } from '../../../languages/en';
    
        export default {
        name: 'AdjustPrices',
        components: {
            Curves,
            EarthworkTransport,
            EarthworkCut1Lane,
            EarthworkCut2Lanes,
            EarthworkFill1Lane,
            EarthworkFill2Lanes,
            Platform1Lane,
            Platform2Lanes,
            PlatformAquisition1Lanes,
            PlatformAquisition2Lanes,
            PlatformBridge,
            PlatformBridge1Lane,
            PlatformBridge2Lanes,
            PlatformTunnel,
            PlatformTunnel1Lane,
            PlatformTunnel2Lanes,
        },
        async mounted(){
        await this.getConfigById({id: this.$route.params.id})
        await this.getChangesList()
            .then(()=>{
                setTimeout(()=>{
                    if(this.capexChanged === true ){
                        this.setChangedFields(this.numerosUnicosOrdenados, this.valuesParamsKeys)
                        .then(()=>{
                            if(this.fieldCapexChanged == false){
                                this.clickSaveExit()
                            }
                        })
                    } else if( this.capexChanged === false && this.curvesChanged === true ){
                        this.paramSelected.values_params.superstructure.central_barrier = this.selectedLanguage['adjustParameters_component_text16'];
                        this.paramSelected.values_params.superstructure.side_barrier = this.selectedLanguage['adjustParameters_component_text16'];
                        this.setCurvesTab(this.listValuesCurve, this.valuesParamsKeys)
                        .then(()=>{
                            if(this.fieldCurvesChanged == false){
                                this.clickSaveExit()
                            }
                        })
                    } else if( this.capexChanged === false && this.curvesChanged === false && this.platformChanged === true ){
                        this.setPlatformTab(this.listValuesPlatform, this.valuesParamsKeys)
                        .then(()=>{
                            if(this.fieldPlatformChanged == false){
                                this.clickSaveExit()
                            }
                        })
                    } else if( this.capexChanged === false && this.curvesChanged === false && this.platformChanged === false && this.earthWorkChanged === true ){
                        this.setEarthWTab(this.listValuesEarthW, this.valuesParamsKeys)
                        .then(()=>{
                            if(this.fieldEarthWorkChanged == false){
                                this.clickSaveExit()
                            }
                        })
                    }else{
                        this.clickSaveExit()
                    }
                    this.loadVersionData = false
                },500)
            })

            this.iniciarCentralBarrierSwitch();
            this.iniciarCentral_barrier();
            this.iniciarSide_barrier();
        },
        data() {
            return {
                listValuesCurve: listValuesParams,
                listValuesPlatform: listValuesParamsPlatform,
                listValuesEarthW: listValuesParamsEarthW,
                
                capexChanged: false,
                curvesChanged: false,
                platformChanged: false,
                earthWorkChanged: false,

                fieldCapexChanged: false,
                fieldCurvesChanged: false,
                fieldPlatformChanged: false,
                fieldEarthWorkChanged: false,

                loadVersionData: true,

                numerosUnicosOrdenados: undefined,
                valuesParamsKeys: undefined,
                notInUse: false,
                logoIconUrl: '/img/icons/logo_icon.webp',
                permanent: false,
                drawer: true,
                tab: 'expropriation',
                color: '#0A7373',
                loading1: false,
                loading2: false,
                capexChangeList: undefined,
                centralBarrier: false,
                sideBarrier: true,
                centralBarrierSwitch: this.selectedLanguage['adjustParameters_component_text14'],
                selectedOptions: {
                    centralBarrierSwitch: this.selectedLanguage['adjustParameters_component_text14'],
                    central_barrier: this.selectedLanguage['adjustParameters_component_text16'],
                    side_barrier: this.selectedLanguage['adjustParameters_component_text16'],
                },
                callWatch: undefined,

                capexListSelected: undefined,
                capexSelectList: undefined,
                selectToSetCapexList: undefined,
                initialList: this.$store.getters.currentCalc.capex.Variables,
                capexSelect: false,
                itemOnList: undefined,
                itemOnList2: undefined,
                itemOnList3: undefined,
                itemOnList4: undefined,
            };
        },
        computed: {
            steps () {
            return this.$store.getters.steps('railway')
            },
            status () {
            return this.$store.getters.status(this.$route.params.id)
            },
            ...mapGetters(['profile','selectedExpropriation','paramSelected', 'selectedCapex', 'paramsChanges', 'configDefaults', 'newDefault','newCapexDetails','newExpropriation','newCapex','newRailTypes','newCurves', 'profile']),
            expropriationHeaders () {
            try{
                return [
                { text: 'Code', value: 'Code', sortable: false},
                { text: 'Description', value: 'Description', sortable: false},
                { text: 'Cost ('+this.paramSelected.currency+'/m²)', value: 'Cost', sortable: false},
                ]
            } catch(error) {
                return [
                { text: 'Name', value: 'name', sortable: false},
                { text: 'Value (/m²)', value: 'value', sortable: false},
                ]
            }
            },
            capexHeaders () {
            try {
                return [
                { text: 'Code', value: 'Code', sortable: false},
                { text: 'Description', value: 'Description', sortable: false},
                { text: 'Unit', value: 'Unit', sortable: false},
                { text: 'Unit Cost ('+this.paramSelected.currency+')', value: 'Unit Cost', sortable: false},
                { text: 'Risk (%)', value: 'Risk', sortable: false},
                ]
            } catch(error) {
                return [
                { text: 'Code', value: 'Code', sortable: false},
                { text: 'Description', value: 'Description', sortable: false},
                { text: 'Unit', value: 'Unit', sortable: false},
                { text: 'Unit Cost ()', value: 'Unit Cost', sortable: false},
                { text: 'Risk (%)', value: 'Risk', sortable: false},
                ]
            }
            },
            selectedLanguage() {
                if (this.profile['language'] === 'en') {
                    return english;
                } else {
                    return portuguese;
                }
            },
        },
        methods: {
            async setSelected(){
        if(!localStorage.getItem("selectedItem")){
            this.selectToSetCapexList = this.selectitemBackup
            } else if(!JSON.parse(localStorage.getItem("selectedItem"))){
            this.selectToSetCapexList = this.selectitemBackup
            } else{
            this.selectToSetCapexList = await JSON.parse(localStorage.getItem("selectedItem"))
            }
        },
            async exitSelecao(){
            if(!localStorage.getItem("selectedItem")){
                this.selectToSetCapexList = this.selectitemBackup
                } else if(!JSON.parse(localStorage.getItem("selectedItem"))){
                this.selectToSetCapexList = this.selectitemBackup
                } else{
                this.selectToSetCapexList = await JSON.parse(localStorage.getItem("selectedItem"))
                }
            document.getElementsByClassName(this.selectToSetCapexList)[0].style.display = 'none'
            this.selectToSetCapexList = undefined
        },
        async saveSelecao(item){

            var elementSelected = JSON.parse(window.localStorage.getItem('selectedItem'))
            // console.log('Selected Item:', elementSelected)
            var element = document.getElementById(elementSelected)
            // console.log('element is', element, 'is', elementSelected)

            var indexIs = this.currentCalc.capex.CAPEX.Code.indexOf(this.selectToSetCapexList)

            this.currentCapex[indexIs]['Description'] = item[0]
            document.getElementsByClassName(this.selectToSetCapexList+'value')[0].textContent = item[0]

            this.currentCapex[indexIs]['Unit'] = item[1]
            document.getElementsByClassName(this.selectToSetCapexList+'value')[1].textContent = item[1]

            this.currentCapex[indexIs]['Unit Cost'] = item[2]
            // document.getElementsByClassName(this.selectToSetCapexList+'value')[2].textContent = item[2]

            document.getElementsByClassName(this.selectToSetCapexList)[0].style.display = 'none'

            var icon = document.createElement('span')
            icon.className = 'mdi mdi-pencil'

            element.insertBefore(icon, element.firstChild)

        },

        async setSelectsOnTable(){

            var selectToSetCapexList;

            var ListenerKeys = []
            ListenerKeys = Object.keys(this.$store.getters.currentCalc.capex.Variables)
            var self = this;

            for(let i = 0; i < ListenerKeys.length; i++){
            document.getElementById(ListenerKeys[i]).style.cursor = 'pointer'
            document.getElementById(ListenerKeys[i]).addEventListener('click', function(e) {
                selectToSetCapexList = e.target.id
                window.localStorage.setItem('selectedItem', ('"'+selectToSetCapexList+'"'))
                this.selectitemBackup = ('"'+selectToSetCapexList+'"')
                self.setSelected()
                document.getElementsByClassName(e.target.id)[0].style.display = 'block'
            });
            // document.getElementsByClassName(ListenerKeys[i]).onclick = function() {alert('clicked');};
            }

        },
            iniciarCentralBarrierSwitch(){
            this.centralBarrierSwitch = this.selectedLanguage['adjustParameters_component_text14'];
            this.selectedOptions.centralBarrierSwitch = this.selectedLanguage['adjustParameters_component_text14'];
            },
            iniciarCentral_barrier(){ return this.selectedOptions.central_barrier = this.selectedLanguage['adjustParameters_component_text16'];},
            iniciarSide_barrier(){ return this.selectedOptions.side_barrier = this.selectedLanguage['adjustParameters_component_text16'];},
            async getChangesList(){

const changesList  = this.paramSelected.version_change

const regex = /\d+/

let numerosSet = new Set()

changesList.change.forEach(item => {
if (item.startsWith('CapexCapex')) {
    const numerosEncontrados = item.match(regex)
    if (numerosEncontrados) {
    numerosEncontrados.forEach(numero => numerosSet.add(Number(numero)))
    }
}
});

let numerosUnicosOrdenados = Array.from(numerosSet).sort((a, b) => a - b);

this.numerosUnicosOrdenados = numerosUnicosOrdenados

const dataArray = [...changesList.add, ...changesList.change];

for (let i = 0 ; i < dataArray.length; i++) {
                    if (dataArray[i].startsWith('ValuesParams')) {
                        if (dataArray[i].startsWith('ValuesParamsStructure') ||
                        dataArray[i].startsWith('ValuesParamsPlatform') ||
                        dataArray[i].startsWith('ValuesParamsSuperstructure') ||
                        dataArray[i] == 'ValuesParamsEarthworkAvgEarthworkWidth' ||
                        dataArray[i] == 'ValuesParamsEarthworkMaxFill' ||
                        dataArray[i] == 'ValuesParamsEarthworkMinStrucHeightUp' ||
                        dataArray[i] == 'ValuesParamsEarthworkMaxCut' ||
                        dataArray[i] == 'ValuesParamsEarthworkMinStrucHeightDown' ||
                        dataArray[i].startsWith('ValuesParamsOthers')) {
                            this.platformChanged = true
                        } else if (dataArray[i].startsWith('ValuesParamsSuperelevation') || dataArray[i].startsWith('ValuesParamsAxisGeometry')) {
                            this.curvesChanged = true
                        } else if ( dataArray[i].startsWith('ValuesParamsEarthwork') && 
                        dataArray[i] != 'ValuesParamsEarthworkAvgEarthworkWidth' &&
                        dataArray[i] != 'ValuesParamsEarthworkMaxFill'&&
                        dataArray[i] != 'ValuesParamsEarthworkMinStrucHeightUp'&&
                        dataArray[i] != 'ValuesParamsEarthworkMaxCut'&&
                        dataArray[i] != 'ValuesParamsEarthworkMinStrucHeightDown'
                        ) {
                            this.earthWorkChanged = true
                        }
                    } else if (dataArray[i].startsWith('Capex')){
                        this.capexChanged = true
                    }
                }

const valuesParamsKeys = await Object.values(dataArray).filter(value => value.startsWith('ValuesParams'))

this.valuesParamsKeys = valuesParamsKeys

},

async setChangedFields(numerosUnicosOrdenados){
                var finalIs = numerosUnicosOrdenados.length
                var currentIs = 0
                    for(let i = 0; i < numerosUnicosOrdenados.length; i++){
                        if(document.getElementById(numerosUnicosOrdenados[i])){
                            document.getElementById(numerosUnicosOrdenados[i]).style.display = "table-row"
                            document.getElementById(numerosUnicosOrdenados[i]).style.opacity = "1"
                            document.getElementById(numerosUnicosOrdenados[i]).style.fontWeight = "500"
                            this.fieldCapexChanged = true
                        }
                        currentIs = i
                        if((currentIs+1) === finalIs){
                            if(this.fieldCapexChanged === false){
                                this.capexChanged = false
                                this.tab = 0
                            }
                        }
                    }
            },

            async setCurvesTab(unset, set){
                //var newList = []
                //var divSelected = document.getElementById('curveNewItems')
                    for(let i = 0; i < unset.length; i++){
                        if(document.getElementById(unset[i])){
                            // document.getElementById(unset[i]).style.opacity = "0.2"
                            document.getElementById(unset[i]).style.display = this.selectedLanguage['adjustParameters_component_text16']
                            // console.log('removendo items')
                        }
                    }

                var finalIs = set.length
                var currentIs = 0

                    for(let i = 0; i < set.length; i++){
                        if (set[i].startsWith('ValuesParamsSuperelevation') || set[i].startsWith('ValuesParamsAxisGeometry')){
                            if(document.getElementById(set[i])){
                                document.getElementById(set[i]).style.display = "flex"
                                document.getElementById(set[i]).style.margin = "10px"
                                document.getElementById(set[i]).style.opacity = "1"
                                this.fieldCurvesChanged = true
                            }
                        }
                        currentIs = i
                        //else {
                            //if (set[i].startsWith('ValuesParams')) {
                                //if (set[i].startsWith('ValuesParamsSuperelevation') || set[i].startsWith('ValuesParamsAxisGeometry')) {
                                    //divSelected.innerHTML+='<div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between;"><div>'+set[i]+'</div><input type="text" style="border: 2px solid; width: 50%; border-radius: 5px;" id="'+set[i]+'"></div'
                                    //newList.push(set[i])
                                    //console.log('adicionando items')
                                //} else {
                                    //do nothing
                                //}
                            //}
                        //}

                        //this.ListNewCurveItems = newList

                        if((currentIs+1) === finalIs){
                            if(this.fieldCurvesChanged === false){
                                this.curvesChanged = false
                                this.tab = 0
                            }
                        }

                    }
                    //this.checkField(this.ListNewCurveItems)
            },
            async setPlatformTab(unset, set){
                // var newList = []
                // var divSelected = document.getElementById('platformNewItems')
                    for(let i = 0; i < unset.length; i++){
                        if(document.getElementById(unset[i])){
                            // document.getElementById(unset[i]).style.opacity = "0.2"
                            document.getElementById(unset[i]).style.display = this.selectedLanguage['adjustParameters_component_text16']

                            // console.log('removendo items')
                        }
                    }

                var finalIs = set.length
                var currentIs = 0

                    for(let i = 0; i < set.length; i++){
                        if (set[i].startsWith('ValuesParamsStructure') ||
                        set[i].startsWith('ValuesParamsPlatform') ||
                        set[i].startsWith('ValuesParamsSuperstructure') ||
                        set[i] == 'ValuesParamsEarthworkAvgEarthworkWidth' ||
                        set[i] == 'ValuesParamsEarthworkMaxFill' ||
                        set[i] == 'ValuesParamsEarthworkMinStrucHeightUp' ||
                        set[i] == 'ValuesParamsEarthworkMaxCut' ||
                        set[i] == 'ValuesParamsEarthworkMinStrucHeightDown' ||
                        set[i].startsWith('ValuesParamsOthers')){
                            if(document.getElementById(set[i])){
                                document.getElementById(set[i]).style.display = "flex"
                                document.getElementById(set[i]).style.margin = "10px"
                                document.getElementById(set[i]).style.opacity = "1"
                                this.fieldPlatformChanged = true
                                
                                console.log('platform ', set[i])
                                
                                console.log('platform tab ', this.tab)
                            }
                        }
                        currentIs = i
                        // else {
                        //     if (set[i].startsWith('ValuesParams')) {
                        //         if (set[i].startsWith('ValuesParamsStructure') ||
                        //         set[i].startsWith('ValuesParamsPlatform') ||
                        //         set[i].startsWith('ValuesParamsSuperstructure') ||
                        //         set[i].startsWith('ValuesParamsOthers')) {
                        //             divSelected.innerHTML+='<div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between;"><div>'+set[i]+'</div><input type="text" style="border: 2px solid; width: 50%; border-radius: 5px;" id="'+set[i]+'"></div'
                        //             newList.push(set[i])
                        //             console.log('adicionando items')
                        //         } else {
                        //             //do nothing
                        //         }
                        //     }
                        // }

                        // this.ListNewPlatformItems = newList

                        if((currentIs+1) === finalIs){
                            if(this.fieldPlatformChanged === false){
                                this.platformChanged = false
                                this.tab = 0
                            }
                        }

                    }
                    // this.checkField(this.ListNewPlatformItems)
            },
            async setEarthWTab(unset, set){
                // var newList = []
                // var divSelected = document.getElementById('earthworkNewItems')
                    for(let i = 0; i < unset.length; i++){
                        if(document.getElementById(unset[i])){
                            // document.getElementById(unset[i]).style.opacity = "0.2"
                            document.getElementById(unset[i]).style.display = this.selectedLanguage['adjustParameters_component_text16']
                        }
                    }

                var finalIs = set.length
                var currentIs = 0

                    for(let i = 0; i < set.length; i++){
                        if ( set[i].startsWith('ValuesParamsEarthwork')  && 
                        set[i] != 'ValuesParamsEarthworkAvgEarthworkWidth' &&
                        set[i] != 'ValuesParamsEarthworkMaxFill'&&
                        set[i] != 'ValuesParamsEarthworkMinStrucHeightUp'&&
                        set[i] != 'ValuesParamsEarthworkMaxCut'&&
                        set[i] != 'ValuesParamsEarthworkMinStrucHeightDown'){
                            if(document.getElementById(set[i])){
                                document.getElementById(set[i]).style.display = "flex"
                                document.getElementById(set[i]).style.margin = "10px"
                                document.getElementById(set[i]).style.opacity = "1"
                                this.fieldEarthWorkChanged = true
                                
                                console.log('earthwork ', set[i])
                                
                                console.log('earthwork tab ', this.tab)
                            }
                        }
                        currentIs = i
                        // else {
                        //     if (set[i].startsWith('ValuesParams')) {
                        //         if (set[i].startsWith('ValuesParamsEarthwork')) {
                        //             divSelected.innerHTML+='<div style="display: flex; flex-direction: row; width: 100%; justify-content: space-between;"><div>'+set[i]+'</div><input type="text" style="border: 2px solid; width: 50%; border-radius: 5px;" id="'+set[i]+'"></div'
                        //             newList.push(set[i])
                        //             console.log('adicionando items')
                        //         } else {
                        //             //do nothing
                        //         }
                        //     }
                        // }

                        // this.ListNewEarthworkItems = newList

                        if((currentIs+1) === finalIs){
                            if(this.fieldEarthWorkChanged === false){
                                console
                                this.earthWorkChanged = false
                                this.tab = 0
                            }
                        }

                    }
                    // this.checkField(this.ListNewEarthworkItems)
            },

            // checkField(lista){
            //     var j = 0;
            //     var character='';
            //     for(let i = 0; i < lista.length; i++){

            //         lista[i].replace('ValuesParams', 'values_params')

            //         while (j <= lista[i].length){
            //         character = lista[i].charAt(i);
            //         if (!isNaN(character * 1)){
            //             console.log('é número', character)
            //         }else{
            //             if (character == character.toUpperCase()) {
            //                 console.log('Colocar ponto aqui', character)
            //             }
            //             if (character == character.toLowerCase()){
            //                 console.log('não fazer nada, não é caixa alto', character)
            //             }
            //         }
            //         j++;
            //     }
            //     }
            // },

            redirectFunc(){
            if(process.env.VUE_APP_VAR_SELECT== 'prd'){
                this.$router.push({ name: 'billingMercadoPago' })
                } else {
                this.$router.push({ name: 'billing' })
                }
            },
            callWatcher(value){
                this.callWatch = value
            },
            ...mapActions(['getConfigs','updateSelectedDeprecated','updateSelectedExpropriation','updateSelectedStore','getAppDefaults','getConfigById','updateSelectedCapex','updateSelectedRasterBudget','updateSelectedParams','getUpdatedDefault']),
            save() {},
            cancel() {},
            open() {},
            close() {},
            expropriationItemStyle(item) {
            if(item.Cost!=undefined){ return 'capexLastLevel'}
            if(item.Level==0){ return 'capexZeroLevel'}
            if(item.Level==1){ return 'capexFirstLevel'}
            else{ return 'capexLastLevel'}
            },
            capexItemStyle(item) {
            if(item.Unit!=0){ return 'capexLastLevel'}
            if(item.Level==0){ return 'capexZeroLevel'}
            if(item.Level==1){ return 'capexFirstLevel'}
            if(item.Level==2){ return 'capexSecondLevel'}
            if(item.Level==3){ return 'capexThirdLevel'}
            return ''
            },
            async clickSaveExit() {
            this.loading1 = true
            await (
                this.updateSelectedRasterBudget(),
                this.updateSelectedCapex(),
                this.updateSelectedParams(),
                this.getConfigs()
                )
                this.$router.push({ name: 'new_roadway' })
            this.loading1 = false
            },
            async clickCancelExit() {
            this.loading2 = true
            await this.goStep(1)
            this.loading2 = false
            },
            onCentralBSelected(value) {
            if(value === this.selectedLanguage['adjustParameters_component_text14']){
                this.centralBarrier = false
                this.paramSelected.values_params.superstructure.central_barrier = this.selectedLanguage['adjustParameters_component_text16'];
                this.selectedOptions.central_barrier = this.selectedLanguage['adjustParameters_component_text16'];
            } else {
                this.centralBarrier = true
            }
            // this.paramSelected.values_params.platform.lanes = value;
            this.selectedOptions.centralBarrierSwitch = value;
            this.callWatch = value
            },
            onLanesSet(value){
            this.paramSelected.values_params.platform.lanes = Number(value);
            this.callWatch = Number(value)
            },
            onCentralBarrierSelected(value) {
            this.paramSelected.values_params.superstructure.central_barrier = value;
            this.selectedOptions.central_barrier = value;
            this.callWatch = value
            },
            onSideBarrierSelected(value) {
            this.paramSelected.values_params.superstructure.side_barrier = value;
            this.selectedOptions.side_barrier = value;
            this.callWatch = value
            },
        },
        watch:{
            callWatch:{
            handler(){
                // no central barrier
                if( this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text16']){
                this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_1_lane'
                this.paramSelected.values_params.imgs.bridge = 'bridge_section_1_lane'
                this.paramSelected.values_params.imgs.cut = 'cut_1_lane'
                this.paramSelected.values_params.imgs.fill = 'fill_1_lane'
                this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_1_lane'
                this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_1_lane'
                this.paramSelected.values_params.imgs.structure_total_width = ((2)+(2*this.paramSelected.values_params.platform.sholder)+(2*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

                } else if( this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text17']){
                this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_1_lane_jersey'
                this.paramSelected.values_params.imgs.bridge = 'bridge_section_1_lane_jersey'
                this.paramSelected.values_params.imgs.cut = 'cut_1_lane'
                this.paramSelected.values_params.imgs.fill = 'fill_1_lane_jersey'
                this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_jersey_1_lane'
                this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_1_lane'
                this.paramSelected.values_params.imgs.structure_total_width = ((2)+(2*this.paramSelected.values_params.platform.sholder)+(2*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

                } else if( this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text14'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text18']){
                this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_1_lane_metalguard'
                this.paramSelected.values_params.imgs.bridge = 'bridge_section_1_lane_metalguard'
                this.paramSelected.values_params.imgs.cut = 'cut_1_lane'
                this.paramSelected.values_params.imgs.fill = 'fill_1_lane_metal_guard'
                this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_metal_guard_1_lane'
                this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_1_lane'
                this.paramSelected.values_params.imgs.structure_total_width = ((2)+(2*this.paramSelected.values_params.platform.sholder)+(2*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

                }
                // yes central barrier
                if( this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text16']){
                this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes'
                this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes'
                this.paramSelected.values_params.imgs.cut = 'cut_2_lanes'
                this.paramSelected.values_params.imgs.fill = 'fill_2_lanes'
                this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes'
                this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes'
                this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))
                } /* central e side iguais */ else if( this.paramSelected.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text17'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text17']){
                this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_jersey_lanes'
                this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_jersey'
                this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_jersey'
                this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_jersey'
                this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_jersey_2_lanes'
                this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
                this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))
                } else if( this.paramSelected.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text17'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text17']){
                this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_jersey_2'
                this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_jersey_2'
                this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_jersey'
                this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_jersey_2'
                this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_jersey_2_lanes_2'
                this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
                this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

                } else if( this.paramSelected.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text18'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text18']){
                this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_metalguard'
                this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_metal_guard'
                this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
                this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_metal_guard'
                this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_metal_guard_2_lanes'
                this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
                this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

                } else if( this.paramSelected.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text18'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text18']){
                this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_metalguard_2'
                this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_metal_guard'
                this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
                this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_metal_guard_2'
                this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_metal_guard_2_lanes_2'
                this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
                this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

                } /* side none */ else if( this.paramSelected.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text17'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text16']){
                this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_J'
                this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_N_J'
                this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_jersey'
                this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_N_J'
                this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_N_J'
                this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
                this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

                } else if( this.paramSelected.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text17'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text16']){
                this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_J_2'
                this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_N_J'
                this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_jersey'
                this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_N_J_2'
                this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_N_J_2'
                this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
                this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

                } else if( this.paramSelected.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text18'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text16']){
                this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_M'
                this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_N_M'
                this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
                this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_N_M'
                this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_N_M'
                this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
                this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

                } else if( this.paramSelected.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text18'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text16']){
                this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_M_2'
                this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_N_M'
                this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
                this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_N_M_2'
                this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_N_M_2'
                this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
                this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

                } /* central e side diferentes */ else if( this.paramSelected.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text18'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text17']){
                this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_J_M'
                this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_J_M'
                this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_jersey'
                this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_J_M'
                this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_J_M'
                this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
                this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

                } else if( this.paramSelected.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text18'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text17']){
                this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_J_M_2'
                this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_J_M'
                this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_jersey'
                this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_J_M_2'
                this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_J_M_2'
                this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
                this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

                } else if( this.paramSelected.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text17'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text18']){
                this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_M_J'
                this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_M_J'
                this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
                this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_M_J'
                this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_M_J'
                this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
                this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

                } else if( this.paramSelected.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text17'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text18']){
                this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_M_J_2'
                this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_M_J_2'
                this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
                this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_M_J_2'
                this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_M_J_2'
                this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
                this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

                } /* central none */ else if( this.paramSelected.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text17']){
                this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_J'
                this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_N_J'
                this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_jersey'
                this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_N_J'
                this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_N_J'
                this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
                this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

                } else if( this.paramSelected.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text17']){
                this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_J_2'
                this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_N_J'
                this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_jersey'
                this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_N_J_2'
                this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_N_J_2'
                this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_jersey'
                this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

                } else if( this.paramSelected.values_params.platform.median_width <= 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text18']){
                this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_M'
                this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_N_J'
                this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
                this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_N_M'
                this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_2_lanes_N_M'
                this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
                this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

                } else if( this.paramSelected.values_params.platform.median_width > 5 && this.selectedOptions.centralBarrierSwitch == this.selectedLanguage['adjustParameters_component_text15'] && this.selectedOptions.central_barrier == this.selectedLanguage['adjustParameters_component_text16'] && this.selectedOptions.side_barrier == this.selectedLanguage['adjustParameters_component_text18']){
                this.paramSelected.values_params.imgs.aquisition = 'Road_and_lanes_2_lanes_N_M_2'
                this.paramSelected.values_params.imgs.bridge = 'bridge_section_2_lanes_N_M'
                this.paramSelected.values_params.imgs.cut = 'cut_2_lanes_metal_guard'
                this.paramSelected.values_params.imgs.fill = 'fill_2_lanes_N_M_2'
                this.paramSelected.values_params.imgs.roads = 'Road_and_lanes_jersey_2_lanes_2'
                this.paramSelected.values_params.imgs.tunnel = 'tunnel_section_2_lanes_metal_guard'
                this.paramSelected.values_params.imgs.structure_total_width = ((12)+(2)+(2*this.paramSelected.values_params.platform.sholder)+(4*(this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))))

                }
                this.paramSelected.values_params.imgs.has_central_barrier = this.selectedOptions.centralBarrierSwitch
                this.paramSelected.values_params.imgs.structure_lanes_total_width = (this.paramSelected.values_params.platform.lanes*(this.paramSelected.values_params.platform.lane))
                this.paramSelected.values_params.imgs.asphalt_depht = (Number(this.paramSelected.values_params.superstructure.first_asphalt_layer_depth)+Number(this.paramSelected.values_params.superstructure.second_asphalt_layer_depth))
            }
            }
        },
        }
    </script>

    <style scoped>

        .selecao{
            display: none;
            position: fixed;
            z-index: 9999;
            background-color: white;
            padding: 30px;
            border-radius: 10px;
            top: 25%;
            left: 50%;
            transform: translate(-50%, 0);
        }
        .capexLastLevel {
        font-style: italic
        }
        .capexZeroLevel {
        background-color: #0A7373;
        font-weight: bold
        }
        .capexFirstLevel {
        background-color: #338D8C;
        font-weight: bold
        }
        .capexSecondLevel {
        background-color: #36bebc;
        font-weight: bold
        }
        .capexThirdLevel {
        background-color: #41e2df;
        font-weight: bold
        }
    
        @media only screen and (max-width: 576px) {
            .codeS {
                display: none;
            }
        .trBody{
            display: flex;
            justify-content: space-between;
        }
        }
    </style>